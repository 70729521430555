<template>
  <div class="page_content_wrap">
    <div class="risk_slider_wrap">
      <div class="container">
        <div class="risk_slider_box" v-if="!isLoading">
          <div class="risk_slider_box_count text-center py-3">
            <h4 class="m-0 text-primary">
              {{ current + 1
              }}<small class="text-dark">/{{ filteredQuestions.length }}</small>
            </h4>
          </div>

          <div class="swipe_slider">
            <div class="swipe_slider_status">
              <i class="fas fa-times-circle"></i>
              <i class="fas fa-check-circle"></i>
            </div>

            <div class="swipe_slider_cards">
              <div
                class="swipe_slider_card shadow"
                :key="item.id"
                v-for="item in filteredQuestions"
              >
                <img
                  class="img-fluid mb-3"
                  :src="item.image"
                />
                <h4 class="text-dark mb-2">
                  {{ item.title }}
                </h4>
                <p class="text-dark">{{ item.content }}</p>
              </div>
            </div>

            <div class="swipe_slider_buttons">
              <!-- <button id="nope" class="btn btn-lg btn-danger" @click="switchPage(false)">
			          <i class="fas fa-times pr-1"></i> DOESN’T APPLY
						</button> -->
              <!-- <button id="love" class="btn btn-lg btn-success" @click="switchPage(true)">
							<i class="fas fa-check pl-1"></i> APPLIES
						</button> -->
              <a
                href="javascript:void(0);"
                id="nope"
                class="btn btn-lg btn-danger"
                @click="switchPage(false)"
              >
                <i class="fas fa-times pr-1"></i> DOESN’T APPLY
              </a>
              <a
                href="javascript:void(0);"
                id="love"
                class="btn btn-lg text-white btn-success"
                @click="switchPage(true)"
              >
                <i class="fas fa-check pl-1"></i> APPLIES
              </a>
            </div>
          </div>
        </div>

        <div class="loader" v-if="isLoading"></div>

      </div>
    </div>

  </div>
</template>


<script>

import { mapGetters, mapActions, mapMutations} from "vuex";
import { getContact, updateContact } from '@/utils/firebase'
import QUESTIONS from '@/data/personal-details-questions'
import RISK_ASSESSMENT_CATEGORIES from '@/data/risk-assessment-categories'
import { DOES_NOT_APPLY_VALUE, APPLIES_TO_ME_VALUE } from '@/utils/constants'
import { parse } from 'query-string'

const cardData = Object.keys(RISK_ASSESSMENT_CATEGORIES)
  .map(category =>
    RISK_ASSESSMENT_CATEGORIES[category].questions.map(question => {
      return {
        image: question.cardImage,
        color: RISK_ASSESSMENT_CATEGORIES[category].color,
        icon: RISK_ASSESSMENT_CATEGORIES[category].icon,
        content: question.cardText,
        questionId: question.id,
        category: RISK_ASSESSMENT_CATEGORIES[category].title,
        title: question.title
      }
    })
  )
  .flat(1)


export default {
  name: "Screen6",
  data() {
    return {
      current: 0,
      allCards: [],
      swipe_sliderContainer: null,
      isLoading: true,
      isSubmitting: false,
      errors: [],
      filteredQuestions: []
    };
  },
  computed: {
    ...mapGetters({
      getUserId: "getUserId",
      getUser: "getUser",
    }),
    ...mapGetters("wizard", {
      riskList: "getRisk",
      survey: "getSurvey"
    }),
  },
  mounted() {
    
    this.isLoading = true;
    const uu = this.getUser
    const contactId = uu.email
    getContact(contactId).then(response => {
      //Object.keys(QUESTIONS)
      console.log({response})
      let prop = []
      if (response && response.data && response.data.properties) {
        props = response.data.properties
      }
      console.log('response.data.properties');
      console.log(prop);
      this.SET_SURVEY(prop);

      this.filteredQuestions = cardData.filter(question => {
        if (
          parseInt(prop.age) > 65 &&
          question.questionId === 'healthcare_costs_age_65_and_under'
        )
          return false

        if (
          prop.are_you_retired === 'Retired' &&
          [
            'the_salary_cliff',
            'retirement_income_shock',
            'early_retirement_income_shortfall',
            'cancelled_plans'
          ].includes(question.questionId)
        )
          return false

        return true
      })

      console.log('filteredQuestions');
      console.log(this.filteredQuestions);

      this.isLoading = false;
      setTimeout(() => {
        this.init();  
      }, 10);
      
    }).catch(error => {
      console.log('error');
      console.log(error);
      this.isLoading = false;
      setTimeout(() => {
        this.init();  
      }, 10);
    });

  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
      updateRiskStatus: "updateRiskStatus",
    }),
     ...mapMutations('wizard', ['SET_SURVEY', 'UPDATE_SURVEY_FIELD']),
    init() {
      this.swipe_sliderContainer = document.querySelector(".swipe_slider");
      this.allCards = document.querySelectorAll(".swipe_slider_card");
      let nope = document.getElementById("nope");
      let love = document.getElementById("love");

      this.initCards();

      this.allCards.forEach((el) => {
        var hammertime = new Hammer(el);

        hammertime.on("pan", (event) => {
          el.classList.add("moving");
        });

        hammertime.on("pan", (event) => {
          if (event.deltaX === 0) return;
          if (event.center.x === 0 && event.center.y === 0) return;

          this.swipe_sliderContainer.classList.toggle(
            "swipe_slider_love",
            event.deltaX > 0
          );
          this.swipe_sliderContainer.classList.toggle(
            "swipe_slider_nope",
            event.deltaX < 0
          );

          var xMulti = event.deltaX * 0.03;
          var yMulti = event.deltaY / 80;
          var rotate = xMulti * yMulti;

          event.target.style.transform =
            "translate(" +
            event.deltaX +
            "px, " +
            event.deltaY +
            "px) rotate(" +
            rotate +
            "deg)";
        });

        hammertime.on("panend", (event) => {
          el.classList.remove("moving");

          this.switchPage(true);

          console.log(this.current);
          if (
            this.swipe_sliderContainer.classList.contains("swipe_slider_love")
          ) {
            this.updateRiskStatus({
              key: this.filteredQuestions[this.current].questionId,
              value: APPLIES_TO_ME_VALUE,
              index: this.current,
              status: true,
            });
          } else if (
            this.swipe_sliderContainer.classList.contains("swipe_slider_nope")
          ) {
            this.updateRiskStatus({
               key: this.filteredQuestions[this.current].questionId,
              value: DOES_NOT_APPLY_VALUE,
              index: this.current,
              status: false,
            });
          }

          this.current++;
          this.updateProgress(0);
          

          this.swipe_sliderContainer.classList.remove("swipe_slider_love");
          this.swipe_sliderContainer.classList.remove("swipe_slider_nope");

          var moveOutWidth = document.body.clientWidth;
          var keep =
            Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

          event.target.classList.toggle("removed", !keep);

          if (keep) {
            event.target.style.transform = "";
          } else {
            var endX = Math.max(
              Math.abs(event.velocityX) * moveOutWidth,
              moveOutWidth
            );
            var toX = event.deltaX > 0 ? endX : -endX;
            var endY = Math.abs(event.velocityY) * moveOutWidth;
            var toY = event.deltaY > 0 ? endY : -endY;
            var xMulti = event.deltaX * 0.03;
            var yMulti = event.deltaY / 80;
            var rotate = xMulti * yMulti;

            event.target.style.transform =
              "translate(" +
              toX +
              "px, " +
              (toY + event.deltaY) +
              "px) rotate(" +
              rotate +
              "deg)";
            this.initCards();
          }
        });
      });

      document.onkeydown = this.checkKey;

      var nopeListener = this.createButtonListener(false);
      var loveListener = this.createButtonListener(true);

      nope.addEventListener("click", nopeListener);
      love.addEventListener("click", loveListener);
    },
    switchPage(status) {
      if(this.current == (this.filteredQuestions.length - 1)) {
        
        // this.$router.push('finances-overview');
        this.submitWizard(this.riskList);
      }
      // if(status == true) {
      //   this.createButtonListener(true);
      // } else {
      //   this.createButtonListener(false);
      // }
    },
    checkKey(e) {
      e = e || window.event;

      if (e.keyCode == "37") {
	    	this.nope.click();
		    // this.createButtonListener(false);
      } else if (e.keyCode == "39") {
		    // this.createButtonListener(true);
        this.love.click();
      }
    },
    initCards(card, index) {
      let newCards = document.querySelectorAll(
        ".swipe_slider_card:not(.removed)"
      );

      newCards.forEach((card, index) => {
        card.style.zIndex = this.allCards.length - index;
        card.style.transform =
          "scale(" + (20 - index) / 20 + ") translateY(-" + 30 * index + "px)";
        card.style.opacity = (10 - index) / 10;
      });

      this.swipe_sliderContainer.classList.add("loaded");
    },
    createButtonListener(love) {
      return (event) => {

        if (
          love
        ) {
           this.updateRiskStatus({
              key: this.filteredQuestions[this.current].questionId,
              value: APPLIES_TO_ME_VALUE,
              index: this.current,
              status: true,
            });

        } else if (
          !love
        ) {
          this.updateRiskStatus({
              key: this.filteredQuestions[this.current].questionId,
              value: DOES_NOT_APPLY_VALUE,
              index: this.current,
              status: false,
          });
        }

        this.current++;
        this.updateProgress(0);
        var cards = document.querySelectorAll(
          ".swipe_slider_card:not(.removed)"
        );
        var moveOutWidth = document.body.clientWidth * 1.5;

        if (!cards.length) return false;

        var card = cards[0];

        card.classList.add("removed");

        if (love) {
          card.style.transform =
            "translate(" + moveOutWidth + "px, -100px) rotate(-30deg)";
        } else {
          card.style.transform =
            "translate(-" + moveOutWidth + "px, -100px) rotate(30deg)";
        }

        this.initCards();

        event.preventDefault();
      };
    },
    submitWizard(values) {
        const uu = this.getUser
        const contactId = uu.email
	
        this.isSubmitting = true;

        updateContact(contactId, values)
        .then((response) => {
          
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
          alert('Something went wrong!')
        })

        const queryParams = parse(location.search)
        if (queryParams['from-review']) {
          this.$router.push({ name : 'risk.review'});
        } else {
          let userObj = {
            riskLink : 'risk.expennses'
          };
          this.updateProgress({ step: -1, data: userObj });
          this.$router.push({ name : 'risk.expennses'});
        }
    }
  }
};
</script>
